<!-- =========================================================================================
  File Name: TheNavbar.vue
  Description: Navbar component
  Component Name: TheNavbar
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
<div class="relative">
    <div class="vx-navbar-wrapper" :class="classObj">
        <vs-navbar class="vx-navbar navbar-custom navbar-skelton" :color="$store.state.navbarDynamicColor" :class="textColor">
            <!-- SM - OPEN SIDEBAR BUTTON -->
            <feather-icon class="sm:inline-flex xl:hidden cursor-pointer p-2 pl-0" icon="MenuIcon" @click.stop="showSidebar" />
            <vs-breadcrumb>
                <li @click="gotoDefaultHomePage()">
                    Home
                    <span class="vs-breadcrum--separator">/</span>
                </li>
                <!-- Dashboard -->
                <li v-if="currentRouteNameBreadcrum == 'Dashboard'">Dashboard</li>
                    <!-- Consumer Dashboard-->
                <li v-if="currentRouteNameBreadcrum == 'ConsumerDashboard'">Dashboard</li>  
                <!-- Leads -->
                <li v-if="currentRouteNameBreadcrum == 'Search Lead'">Search Lead</li>
                <li v-if="currentRouteNameBreadcrum == 'Form Captures'">Form Captures</li>
                <li v-if="currentRouteNameBreadcrum == 'Email Campaigns'">Email Campaigns</li>
                <li v-if="currentRouteNameBreadcrum == 'Reporting'">Reporting</li>
                    <!-- consumer Leads -->
                <li v-if="currentRouteNameBreadcrum == 'ConsumerSearchLeads'">Search Lead</li>
                  <!-- intent -->
                <li v-if="currentRouteNameBreadcrum == 'IntentDashboard'">Dashboard</li>
                <li v-if="currentRouteNameBreadcrum == 'IntentCampaigns'">Campaigns</li>
                <li v-if="currentRouteNameBreadcrum == 'IntentLeads'">Leads</li>
                <li v-if="currentRouteNameBreadcrum == 'IntentCompetitors'">Competitors</li>
                <li v-if="currentRouteNameBreadcrum == 'MyCustomerProfile'">My Customer Profile</li>
                <li v-if="currentRouteNameBreadcrum == 'KeywordSummary'">Keyword Summary</li>
                <li v-if="currentRouteNameBreadcrum == 'PrebuiltCampaigns'">Prebuilt Campaigns</li>
                <!-- Find Anyone -->
                <li v-if="currentRouteNameBreadcrum == 'SearchContacts'">Search Contacts</li>
                <li v-if="currentRouteNameBreadcrum == 'SaveContacts'">Save Contacts</li>
                <li v-if="currentRouteNameBreadcrum == 'SearchCompanies'">Search Companies</li>
                <li v-if="currentRouteNameBreadcrum == 'DataEnrichment'">Data Enrichment</li>
                <!-- Email Marketing -->
                <li v-if="currentRouteNameBreadcrum == 'GettingStarted'">Getting Started</li>
                <li v-if="currentRouteNameBreadcrum == 'EmailDashboard'">Dashboard</li>
                <li v-if="currentRouteNameBreadcrum == 'Contacts'">Contacts</li>
                <li v-if="currentRouteNameBreadcrum == 'Campaigns'">Campaigns</li>
                <li v-if="currentRouteNameBreadcrum == 'Settings'">Settings</li>
                <!-- Call Tracking -->
                <li v-if="currentRouteNameBreadcrum == 'Call Tracking Getting Started'">Call Tracking - Getting Started</li>
                <li v-if="currentRouteNameBreadcrum == 'Call Dashboard'">Call Dashboard</li>
                <li v-if="currentRouteNameBreadcrum == 'Text Msg Inbox'">Text Msg Inbox</li>
                <li v-if="currentRouteNameBreadcrum == 'Numbers'">Numbers</li>
                <li v-if="currentRouteNameBreadcrum == 'Integration'">Integration</li>
                <li v-if="currentRouteNameBreadcrum == 'Reports'">Reports</li>
                <!-- Reviews -->
                <li v-if="currentRouteNameBreadcrum == 'Getting Started'">Review Get Started</li>
                <li v-if="currentRouteNameBreadcrum == 'Review Dashboard'">Review Dashboard</li>
                <li v-if="currentRouteNameBreadcrum == 'Review Details'">Review Details</li>
                <li v-if="currentRouteNameBreadcrum == 'Gather Reviews'">Gather Reviews</li>
                <li v-if="currentRouteNameBreadcrum == 'Landing Pages'">Landing Pages</li>
                <li v-if="currentRouteNameBreadcrum == 'Widgets'">Widgets</li>
                <!-- Client Email -->
                <li v-if="currentRouteNameBreadcrum == 'Email Tracking Getting Started'">Email Tracking - Getting Started</li>
                <li v-if="currentRouteNameBreadcrum == 'Client Email Dashboard'">Client Email Dashboard</li>
                <li v-if="currentRouteNameBreadcrum == 'Email Activity'">Email Activity Stream</li>
                <li v-if="currentRouteNameBreadcrum == 'Client Email Templates'">Client Email Templates</li>
                <!-- Analytics -->
                <li v-if="currentRouteNameBreadcrum == 'Overview'">Analytics - Overview</li>
                <li v-if="currentRouteNameBreadcrum == 'Location'">Analytics - Location</li>
                <li v-if="currentRouteNameBreadcrum == 'Device'">Analytics - Device</li>
                <li v-if="currentRouteNameBreadcrum == 'Engagement'">Analytics - Engagement</li>
                <li v-if="currentRouteNameBreadcrum == 'Referrer'">Analytics - Referrer</li>
                <li v-if="currentRouteNameBreadcrum == 'Entry'">Analytics - Entry</li>
                <li v-if="currentRouteNameBreadcrum == 'Exit'">Analytics - Exit</li>
                <!-- Lead Handling -->
                <li v-if="currentRouteNameBreadcrum == 'Notification Rule'">Lead Handling - Notification Rule</li>
                <li v-if="currentRouteNameBreadcrum == 'Territory Rules'">Lead Handling - Territory Rules</li>
                <li v-if="currentRouteNameBreadcrum == 'Watch / Exclude Rules'">Watch - Exclude Rules</li>
                <!-- My Account -->
                <li v-if="currentRouteNameBreadcrum == 'My Information'">My Information</li>
                <li v-if="currentRouteNameBreadcrum == 'Change Password'">Change Password</li>
                <li v-if="currentRouteNameBreadcrum == 'CRM Integration'">CRM Integration</li>
                <!-- Billing -->
                <li v-if="currentRouteNameBreadcrum == 'Billing Info'">Billing Info</li>
                <li v-if="currentRouteNameBreadcrum == 'ConsumerBillingInfo'">Billing Info</li>
                <li v-if="currentRouteNameBreadcrum == 'Invoice'">Invoice</li>
                <li v-if="currentRouteNameBreadcrum == 'Audit Log'">Audit Log</li>
                <li v-if="currentRouteNameBreadcrum == 'Billing Credit'">Credits</li>
                <!-- Configuration -->
                <li v-if="currentRouteNameBreadcrum == 'User'">Configuration - User</li>
                <li v-if="currentRouteNameBreadcrum == 'Domains'">Configuration - Domains</li>
                <!-- <li v-if="currentRouteNameBreadcrum == 'InstallationOld'">Configuration - Installation</li> -->
                <li v-if="currentRouteNameBreadcrum == 'Installation'">Configuration - Installation</li>
                <li v-if="currentRouteNameBreadcrum == 'Excluded Ip'">Configuration - Excluded Ip</li>
                <li v-if="currentRouteNameBreadcrum == 'Configuration Email Campaigns'">Configuration - Email Campaigns</li>
                <li v-if="currentRouteNameBreadcrum == 'Forms Capture'">Configuration - Forms Capture</li>
                <li v-if="currentRouteNameBreadcrum == 'Filter Companies'">Configuration - Filter Companies</li>
                <li v-if="currentRouteNameBreadcrum == 'Filter Countries'">Configuration - Filter Countries</li>
                <li v-if="currentRouteNameBreadcrum == 'FilterStates'">Configuration - Filter States</li>
                <li v-if="currentRouteNameBreadcrum == 'Filter Contacts'">Configuration - Filter Contacts</li>
                <li v-if="currentRouteNameBreadcrum == 'My Customers'">Configuration - My Customers</li>
                <li v-if="currentRouteNameBreadcrum == 'My Competitors'">Configuration - My Competitors</li>
                <li v-if="currentRouteNameBreadcrum == 'IntegrationPartners'">Configuration - Integration</li>
                <li v-if="currentRouteNameBreadcrum == 'IntegrationSetting'">Configuration - CRM Integration </li>
                <li v-if="currentRouteNameBreadcrum == 'StepWizard'">Configuration - Integration </li>
                    <!-- Configuration consumer -->
                <li v-if="currentRouteNameBreadcrum == 'ConsumerConfigurationBudget'">Configuration - Budget </li>
                <!-- worklfow -->
                <li v-if="currentRouteNameBreadcrum == 'LeadHandlingWorkflow'">Workflow - Lead Handling</li>
                <!-- Job Activity -->
                <li v-if="currentRouteNameBreadcrum == 'IntegrationJobActivity'">Activity - Job Activity</li>
                <!-- Target Company -->
                <li v-if="currentRouteNameBreadcrum == 'TargetCompanyList'">Target Companies - Company List</li>
                <li v-if="currentRouteNameBreadcrum == 'TargetContactList'">Target Contacts - Contact List</li>
                <!-- Reseller -->
                <li v-if="currentRouteNameBreadcrum == 'Customers'">Reseller - Customers</li>
                <li v-if="currentRouteNameBreadcrum == 'Reseller Setting'">Reseller Setting</li>
                <li v-if="currentRouteNameBreadcrum == 'Reseller Manage'">Manage Resellers</li>
                <li v-if="currentRouteNameBreadcrum == 'Reseller Report'">Reseller Reports</li>
                <li v-if="currentRouteNameBreadcrum == 'Customer Activity Report'">Reseller Report</li>
                <li v-if="currentRouteNameBreadcrum == 'Customer Inactive Report'">Reseller Report</li>
                <li v-if="currentRouteNameBreadcrum == 'Consumer Customer Activity Report'">Reseller Report</li>
                <li v-if="currentRouteNameBreadcrum == 'Consumer Reseller Detail Report'">Reseller Report</li>
                <li v-if="currentRouteNameBreadcrum == 'Consumer Reseller Summary Report'">Reseller Report</li>
                <li v-if="currentRouteNameBreadcrum == 'Reseller Billing Map'">Reseller Component Map</li>
                <!-- Need Help -->
                <li v-if="currentRouteNameBreadcrum == 'need-help'">Documentation</li>
                <!-- Other -->
                <li v-if="currentRouteNameBreadcrum == 'view-report'">Report</li>
                <li v-if="currentRouteNameBreadcrum == 'view-report2'">Report</li>
                
                <!-- <li v-if="currentRouteNameBreadcrum == 'GMB Insight'">GMB Insight</li>
                <li v-if="currentRouteNameBreadcrum == 'PPC Adwords'">PPC Adwords</li>
                <li v-if="currentRouteNameBreadcrum == 'Facebook Organic'">Facebook Organic</li>
                <li v-if="currentRouteNameBreadcrum == 'Facebook Paid'">Facebook Paid</li>
                <li v-if="currentRouteNameBreadcrum == 'Seo'">SEO</li>
                <li v-if="currentRouteNameBreadcrum == 'programmatic'">Programmatic</li>
                <li v-if="currentRouteNameBreadcrum == 'PPC-Bing'">PPC-Bing</li>
                <li v-if="currentRouteNameBreadcrum == 'Markup'">Markup</li>
                <li v-if="currentRouteNameBreadcrum == 'dashboard-your-connection'">Your Connection</li>
                <li v-if="currentRouteNameBreadcrum == 'publishing'">Publishing</li>
                <li v-if="currentRouteNameBreadcrum == 'monitoring'">Monitoring</li>
                <li v-if="currentRouteNameBreadcrum == 'settings'">Settings</li> -->
                <!-- <li v-if="currentRouteNameBreadcrum == 'your-connection'">Your Connection</li>
                <li v-if="currentRouteNameBreadcrum == 'watermark'">Watermark</li>
                <li v-if="currentRouteNameBreadcrum == 'local-ranking'">Local Ranking</li>
                <li v-if="currentRouteNameBreadcrum == 'file-manager'">File Manager</li>
                <li v-if="currentRouteNameBreadcrum == 'connected-account'">Connected Account</li>
                <li v-if="currentRouteNameBreadcrum == 'caption'">Caption</li>
                <li v-if="currentRouteNameBreadcrum == 'queue'">Queue</li>
                <li v-if="currentRouteNameBreadcrum == 'recent-post'">Recent Post</li>
                <li v-if="currentRouteNameBreadcrum == 'recent-post'"></li> -->                
            </vs-breadcrumb>
            <vs-spacer />
            <profile-drop-down />
        </vs-navbar>
    </div>
</div>
</template>

<script>
import ProfileDropDown from "./components/ProfileDropDown.vue";
export default {
    name: "the-navbar-vertical",
    props: {
        navbarColor: {
            type: String,
            default: "#fff",
        },
    },
    data() {
        return {
            currentRouteNameBreadcrum: this.$router.currentRoute.name,
        };
    },
    watch: {
        $route() {
            this.currentRouteNameBreadcrum = this.$router.currentRoute.name;
        },
    },
    components: {
        ProfileDropDown,
    },
    computed: {
        navbarColorLocal() {
            return this.$store.state.theme === "dark" && this.navbarColor === "#fff" ?
                "#10163a" :
                this.navbarColor;
        },
        verticalNavMenuWidth() {
            return this.$store.state.verticalNavMenuWidth;
        },
        textColor() {
            return {
                "text-white": (this.navbarColor != "#10163a" &&
                        this.$store.state.theme === "dark") ||
                    (this.navbarColor != "#fff" && this.$store.state.theme !== "dark"),
            };
        },
        windowWidth() {
            return this.$store.state.windowWidth;
        },
        // NAVBAR STYLE
        classObj() {
            if (this.verticalNavMenuWidth == "default") return "navbar-default";
            else if (this.verticalNavMenuWidth == "reduced") return "navbar-reduced";
            else if (this.verticalNavMenuWidth) return "navbar-full";
        },
    },
    methods: {
        showSidebar() {
            this.$store.commit("TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE", true);
        },
        gotoDefaultHomePage() {
            let uid = this.$route.params.id;
            let VVtoken = localStorage.getItem("VVtoken");
            let token = JSON.parse(VVtoken);
            let defaultHomePage = token[uid].DefaultHomePage;
            let redirectDefaultUrl = this.getRedirectDefaultUrl(defaultHomePage);
            this.$router.push(redirectDefaultUrl + uid);
        },
    },
};
</script>
