<!-- =========================================================================================
    File Name: Main.vue
    Description: Main layout
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
    Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
<div class="layout--main" :class="[layoutTypeClass, navbarClasses, footerClasses, {'no-scroll': isAppPage}]">
    <!-- <vx-tour :steps="steps" v-if="!disableThemeTour && (windowWidth >= 1200 && mainLayoutType === 'vertical' && verticalNavMenuWidth == 'default')" /> -->

    <the-customizer v-if="!disableCustomizer" :footerType="footerType" :hideScrollToTop="hideScrollToTop" :navbarType="navbarType" :navbarColor="navbarColor" :routerTransition="routerTransition" @toggleHideScrollToTop="toggleHideScrollToTop" @updateFooter="updateFooter" @updateNavbar="updateNavbar" @updateNavbarColor="updateNavbarColor" @updateRouterTransition="updateRouterTransition" />

    <v-nav-menu :navMenuItems="navMenuItems" title="Visual Visitor" parent=".layout--main" />

    <div id="content-area" :class="[contentAreaClass, {'show-overlay': bodyOverlay}]">
        <div id="content-overlay" />

        <!-- Navbar -->
        <template v-if="mainLayoutType === 'horizontal' && windowWidth >= 1200">
            <the-navbar-horizontal
                :navbarType="navbarType"
                :class="[ {'text-white' : isNavbarDark  && !isThemeDark}, {'text-base'  : !isNavbarDark && isThemeDark}]"
            />

            <div style="height: 62px" v-if="navbarType === 'static'"></div>

            <h-nav-menu
                :class="[ {'text-white' : isNavbarDark  && !isThemeDark}, {'text-base'  : !isNavbarDark && isThemeDark}]"
                :navMenuItems="navMenuItems"
            />
        </template>

        <template v-else>
            <the-navbar-vertical
                :navbarColor="navbarColor"
                :class="[ {'text-white' : isNavbarDark  && !isThemeDark}, {'text-base'  : !isNavbarDark && isThemeDark}]"
            />
        </template>
        <!-- /Navbar -->

        <div class="content-wrapper">
            <div class="router-view">
                <div class="router-content">
                    <transition :name="routerTransition">
                        <div v-if="$route.meta.breadcrumb || $route.meta.pageTitle" class="router-header flex flex-wrap items-center mb-6">
                            <div class="content-area__heading" :class="{'pr-4 border-0 md:border-r border-solid border-grey-light' : $route.meta.breadcrumb}">
                                <h2 class="mb-1">{{ routeTitle }}</h2>
                            </div>

                            <!-- BREADCRUMB -->
                            <vx-breadcrumb class="ml-4 md:block hidden" v-if="$route.meta.breadcrumb" :route="$route" :isRTL="$vs.rtl" />

                            <!-- DROPDOWN -->
                            <vs-dropdown vs-trigger-click class="ml-auto md:block hidden cursor-pointer">
                                <vs-button radius icon="icon-settings" icon-pack="feather" />

                                <vs-dropdown-menu class="w-32">
                                    <vs-dropdown-item>
                                        <div @click="$router.push('/pages/profile').catch(() => {})" class="flex items-center">
                                            <feather-icon icon="UserIcon" class="inline-block mr-2" svgClasses="w-4 h-4" />
                                            <span>Profile</span>
                                        </div>
                                    </vs-dropdown-item>
                                    <vs-dropdown-item>
                                        <div @click="$router.push('/apps/todo').catch(() => {})" class="flex items-center">
                                            <feather-icon icon="CheckSquareIcon" class="inline-block mr-2" svgClasses="w-4 h-4" />
                                            <span>Tasks</span>
                                        </div>
                                    </vs-dropdown-item>
                                    <vs-dropdown-item>
                                        <div @click="$router.push('/apps/email').catch(() => {})" class="flex items-center">
                                            <feather-icon icon="MailIcon" class="inline-block mr-2" svgClasses="w-4 h-4" />
                                            <span>Inbox</span>
                                        </div>
                                    </vs-dropdown-item>
                                </vs-dropdown-menu>
                            </vs-dropdown>
                        </div>
                    </transition>

                    <div class="content-area__content">
                        <back-to-top bottom="5%" :right="$vs.rtl ? 'calc(100% - 2.2rem - 38px)' : '30px'" visibleoffset="500" v-if="!hideScrollToTop">
                            <vs-button icon-pack="feather" icon="icon-arrow-up" class="shadow-lg btn-back-to-top" />
                        </back-to-top>

                        <transition :name="routerTransition" mode="out-in">
                            <router-view @changeRouteTitle="changeRouteTitle" @setAppClasses="(classesStr) => $emit('setAppClasses', classesStr)" />
                        </transition>
                    </div>
                </div>
            </div>
        </div>
        <the-footer :loggedInCompanyName="loggedInCompanyName" />
    </div>
</div>
</template>

<script>
import BackToTop from "vue-backtotop";
import HNavMenu from "@/layouts/components/horizontal-nav-menu/HorizontalNavMenu.vue";
// import navMenuItems        from "@/layouts/components/vertical-nav-menu/navMenuItems.js"
import TheCustomizer from "@/layouts/components/customizer/TheCustomizer.vue";
import TheNavbarHorizontal from "@/layouts/components/navbar/TheNavbarHorizontal.vue";
import TheNavbarVertical from "@/layouts/components/navbar/TheNavbarVertical.vue";
import TheFooter from "@/layouts/components/TheFooter.vue";
import themeConfig from "@/../themeConfig.js";
import VNavMenu from "@/layouts/components/vertical-nav-menu/VerticalNavMenu.vue";

// const VxTour = () => import('@/components/VxTour.vue')

export default {
    components: {
        BackToTop,
        HNavMenu,
        TheCustomizer,
        TheFooter,
        TheNavbarHorizontal,
        TheNavbarVertical,
        VNavMenu,
        // VxTour
    },
    data() {
        return {
            disableCustomizer: themeConfig.disableCustomizer,
            disableThemeTour: themeConfig.disableThemeTour,
            dynamicWatchers: {},
            footerType: themeConfig.footerType || "static",
            hideScrollToTop: themeConfig.hideScrollToTop,
            isNavbarDark: false,
            navbarColor: themeConfig.navbarColor || "#fff",
            navbarType: themeConfig.navbarType || "floating",
            // navMenuItems      : navMenuItems,
            navMenuItems: [],
            routerTransition: themeConfig.routerTransition || "none",
            routeTitle: this.$route.meta.pageTitle,
            steps: [{
                    target: "#btnVNavMenuMinToggler",
                    content: "Toggle Collapse Sidebar."
                },
                {
                    target: ".vx-navbar__starred-pages",
                    content: "Create your own bookmarks. You can also re-arrange them using drag & drop."
                },
                {
                    target: ".i18n-locale",
                    content: "You can change language from here."
                },
                {
                    target: ".navbar-fuzzy-search",
                    content: "Try fuzzy search to visit pages in flash."
                },
                {
                    target: ".customizer-btn",
                    content: "Customize template based on your preference",
                    params: {
                        placement: "left"
                    }
                },
                {
                    target: ".vs-button.buy-now",
                    content: "Buy this awesomeness at affordable price!",
                    params: {
                        placement: "top"
                    }
                }
            ],

            loggedInCompanyName: null
        };
    },
    watch: {
        $route() {
            this.routeTitle = this.$route.meta.pageTitle;
        },
        isThemeDark(val) {
            const color = this.navbarColor == "#fff" && val ? "#10163a" : "#fff";
            this.updateNavbarColor(color);
        },
        "$store.state.mainLayoutType"(val) {
            this.setNavMenuVisibility(val);
            this.disableThemeTour = true;
        }
    },
    computed: {
        bodyOverlay() {
            return this.$store.state.bodyOverlay;
        },
        contentAreaClass() {
            if (this.mainLayoutType === "vertical") {
                if (this.verticalNavMenuWidth == "default")
                    return "content-area-reduced";
                else if (this.verticalNavMenuWidth == "reduced")
                    return "content-area-lg";
                else return "content-area-full";
            }
            // else if(this.mainLayoutType === "boxed") return "content-area-reduced"
            else return "content-area-full";
        },
        footerClasses() {
            return {
                "footer-hidden": this.footerType == "hidden",
                "footer-sticky": this.footerType == "sticky",
                "footer-static": this.footerType == "static"
            };
        },
        isAppPage() {
            return this.$route.meta.no_scroll;
        },
        isThemeDark() {
            return this.$store.state.theme == "dark";
        },
        layoutTypeClass() {
            return `main-${this.mainLayoutType}`;
        },
        mainLayoutType() {
            return this.$store.state.mainLayoutType;
        },
        navbarClasses() {
            return {
                "navbar-hidden": this.navbarType == "hidden",
                "navbar-sticky": this.navbarType == "sticky",
                "navbar-static": this.navbarType == "static",
                "navbar-floating": this.navbarType == "floating"
            };
        },
        verticalNavMenuWidth() {
            return this.$store.state.verticalNavMenuWidth;
        },
        windowWidth() {
            return this.$store.state.windowWidth;
        }
    },
    methods: {
        changeRouteTitle(title) {
            this.routeTitle = title;
        },
        updateNavbar(val) {
            if (val == "static")
                this.updateNavbarColor(this.isThemeDark ? "#10163a" : "#fff");
            this.navbarType = val;
        },
        updateNavbarColor(val) {
            this.$store.dispatch("updateNavbarColor", val);
            // this.navbarColor = val
            // if (val == "#fff") this.isNavbarDark = false
            // else this.isNavbarDark = true
        },
        updateFooter(val) {
            this.footerType = val;
        },
        updateRouterTransition(val) {
            this.routerTransition = val;
        },
        setNavMenuVisibility(layoutType) {
            if (
                (layoutType === "horizontal" && this.windowWidth >= 1200) ||
                (layoutType === "vertical" && this.windowWidth < 1200)
            ) {
                this.$store.commit("TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE", false);
                this.$store.dispatch("updateVerticalNavMenuWidth", "no-nav-menu");
            } else {
                this.$store.commit("TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE", true);
            }
        },
        toggleHideScrollToTop(val) {
            this.hideScrollToTop = val;
        },

        getLoggedInCompanyName() {
            this.$vs.loading();
            this.axios
                .get("/ws/Organization/GetOrganization")
                .then(response => {
                    let data = response.data;
                    this.loggedInCompanyName = data.Org_Name;
                    this.$store.commit("UPDATE_DOMAIN_URL", data.Org_Actual_domain_Name)
                    this.$vs.loading.close();
                })
                .catch(e => {
                    this.$vs.loading.close();
                    this.showError(e);
                });
        },

        getSidebarItems() {
            let uid = this.$route.params.id;
            let VVtoken = localStorage.getItem("VVtoken");
            let token = JSON.parse(VVtoken);
            let isConsumerPlan=token[uid].isConsumerPlan
            let vendastaAccountId = token[uid].vendastaAccountId
            let obj = [{
                    url: "/dashboard/uid/" + uid + "/" + vendastaAccountId,
                    name: "Dashboard",
                    slug: "dashboard",
                    icon: "HomeIcon"
                },
                {
                    url: "/leads",
                    name: "Leads",
                    slug: "leads",
                    icon: "UsersIcon",
                    submenu: []
                },
                {
                    url: "/ecommerce",
                    name: "E-Commerce",
                    slug: "e-commerce",
                    icon: "ShoppingCartIcon",
                    submenu: []
                },
                {
                    url: "/find-anyone",
                    name: "Who to Contact?",
                    slug: "find-anyone",
                    icon: "SearchIcon",
                    submenu: []
                },
                {
                    url: "/intent",
                    name: "Who’s Shopping?",
                    slug: "Intent",
                    icon: "LayersIcon",
                    submenu: []
                },
                {
                    url: "/email-marketing",
                    name: "Email Marketing",
                    slug: "email-marketing",
                    icon: "MailIcon",
                    submenu: []
                },
                {
                    url: "/call-tracking",
                    name: "Call Tracking",
                    slug: "call-tracking",
                    icon: "PhoneIcon",
                    submenu: []
                },
                {
                    url: "/Reputation Management",
                    name: "Reviews",
                    slug: "reputation-management",
                    icon: "StarIcon",
                    submenu: []
                },
                {
                    url: "/client-email",
                    name: "Client Email",
                    slug: "Client-Email",
                    icon: "MailIcon",
                    submenu: []
                },
                {
                    url: "/analytics",
                    name: "Analytics",
                    slug: "Analytics",
                    icon: "BarChartIcon",
                    submenu: []
                },
                {
                    url: "/lead-handling",
                    name: "Lead handling",
                    slug: "lead-handling",
                    icon: "FilterIcon",
                    submenu: []
                },
                {
                    url: "/my-account",
                    name: "My Account",
                    slug: "my-account",
                    icon: "UserIcon",
                    submenu: []
                },
                {
                    url: "/integration-status",
                    name: "Integration Status",
                    slug: "integration-status",
                    icon: "SettingsIcon",
                    submenu: []
                },
                {
                    url: "/Billing",
                    name: "Billing",
                    slug: "billing",
                    icon: "CreditCardIcon",
                    submenu: []
                },
                {
                    url: "/configuration",
                    name: "Configuration",
                    slug: "configuration",
                    icon: "SettingsIcon",
                    submenu: []
                },
                {
                    url: "/Reseller",
                    name: "Reseller",
                    slug: "reseller",
                    icon: "UsersIcon",
                    submenu: []
                },
                {
                    url: "/create-dashboard",
                    name: "Dashboard",
                    slug: "create-dashboard",
                    icon: "HomeIcon",
                    submenu: []
                },
                {
                    url: "/social-automation",
                    name: "Social Automation",
                    slug: "social-automation",
                    icon: "MessageSquareIcon",
                    submenu: []
                },
                {
                    url: "/gmb-management",
                    name: "GMB Management",
                    slug: "gmb-management",
                    icon: "SettingsIcon",
                    submenu: []
                },
                {
                    url: "/need-help",
                    name: "Need Help",
                    slug: "need-help",
                    icon: "HelpCircleIcon",
                    submenu: []
                },
                {
                    url: "/sign-up/uid/" + uid + "/" + vendastaAccountId,
                    name: "Sign Up",
                    slug: "sign-up",
                    icon: "LogInIcon"
                },
                {
                    url: "/sign-up-customer/uid/" + uid + "/" + vendastaAccountId,
                    name: "Sign Up",
                    slug: "sign-up",
                    icon: "LogInIcon"
                },
            ];
            let menuItem = token[uid].menuItems;
            let insertKey
            let targetKey
            let targetElement
            for (var e = 0; menuItem.length > e; e++) {
                if(menuItem[e].MenuItemName == "configuration-installation"){
                    insertKey = e
                }
                if(menuItem[e].MenuItemName == "configuration-connectors"){
                    targetKey = e
                }
            }
            targetElement = menuItem.splice(targetKey, 1);
            menuItem.splice(insertKey+1, 0, targetElement[0]);
            let isDashboard = false;
            let isLeads = false;
            let isEcommerce = false;
            let isFindAnyone = false;
            let isIntent = false;
            let isEmailMarketing = false;
            let isCallTracking = false;
            let isRreviews = false;
            let isClientEmail = false;
            let isAnalytics = false;
            let isLeadHandling = false;
            let isMyAccount = false;
            let isIntegrationStatus = false;
            let isBilling = false;
            let isConfiguration = false;
            let isReseller = false;
            let isCreateDashboard = false;
            let isSocialAutomation = false;
            let isGMBManagement = false;
            // let isNeedHelp = false
            let isSignUp = false;
            let isSignUpConsumer = false;
            for (var i = 0; menuItem.length > i; i++) {
                if (!isDashboard) {
                    if (
                        menuItem[i].MenuItemName.split("-")[0] == "dashboard" &&
                        menuItem[i].Visible == true
                    ) {
                        isDashboard = true;
                    }
                }
                if (!isLeads) {
                    if (
                        menuItem[i].MenuItemName.split("-")[0] == "leads" &&
                        menuItem[i].Visible == true
                    ) {
                        isLeads = true;
                    }
                }
                if (!isFindAnyone) {
                    if (
                        menuItem[i].MenuItemName.split("-")[0] == "findanyone" &&
                        menuItem[i].Visible == true
                    ) {
                        isFindAnyone = true;
                    }
                }
                if(!isIntent) {
                    if (
                        menuItem[i].MenuItemName.split("-")[0] == "intent" &&
                        menuItem[i].Visible == true
                    ) {
                        isIntent = true;
                    }
                }
                if (!isEmailMarketing) {
                    if (
                        menuItem[i].MenuItemName.split("-")[0] == "emailmarketing" &&
                        menuItem[i].Visible == true
                    ) {
                        isEmailMarketing = true;
                    }
                }
                if (!isCallTracking) {
                    if (
                        menuItem[i].MenuItemName.split("-")[0] == "calltracking" &&
                        menuItem[i].Visible == true
                    ) {
                        isCallTracking = true;
                    }
                }
                if (!isRreviews) {
                    if (
                        menuItem[i].MenuItemName.split("-")[0] == "reviews" &&
                        menuItem[i].Visible == true
                    ) {
                        isRreviews = true;
                    }
                }
                if (!isClientEmail) {
                    if (
                        menuItem[i].MenuItemName.split("-")[0] == "clientemail" &&
                        menuItem[i].Visible == true
                    ) {
                        isClientEmail = true;
                    }
                }
                if (!isAnalytics) {
                    if (
                        menuItem[i].MenuItemName.split("-")[0] == "analytics" &&
                        menuItem[i].Visible == true
                    ) {
                        isAnalytics = true;
                    }
                }
                if (!isLeadHandling) {
                    if (
                        menuItem[i].MenuItemName.split("-")[0] == "leadhandling" &&
                        menuItem[i].Visible == true
                    ) {
                        isLeadHandling = true;
                    }
                }
                if (!isMyAccount) {
                    if (
                        menuItem[i].MenuItemName.split("-")[0] == "myaccount" &&
                        menuItem[i].Visible == true
                    ) {
                        isMyAccount = true;
                    }
                }
                if (!isIntegrationStatus){
                    if (
                        menuItem[i].MenuItemName.split("-")[0] == "integrationstatus" &&
                        menuItem[i].Visible == true
                    ) {
                        isIntegrationStatus = true;
                    }
                }
                if (!isBilling) {
                    if (
                        menuItem[i].MenuItemName.split("-")[0] == "billing" &&
                        menuItem[i].Visible == true
                    ) {
                        isBilling = true;
                    }
                }
                if (!isConfiguration) {
                    if (
                        menuItem[i].MenuItemName.split("-")[0] == "configuration" &&
                        menuItem[i].Visible == true
                    ) {
                        isConfiguration = true;
                    }
                }
                if (!isReseller) {
                    if (
                        menuItem[i].MenuItemName.split("-")[0] == "reseller" &&
                        menuItem[i].Visible == true
                    ) {
                        isReseller = true;
                    }
                }
                if (!isCreateDashboard) {
                    if (
                        (menuItem[i].MenuItemName == "dashboard-gmbinsights" ||
                            menuItem[i].MenuItemName == "dashboard-ppcadwords" ||
                            menuItem[i].MenuItemName == "dashboard-facebookorganic" ||
                            menuItem[i].MenuItemName == "dashboard-facebookpaid" ||
                            menuItem[i].MenuItemName == "dashboard-seo" ||
                            menuItem[i].MenuItemName == "dashboard-programmatic" ||
                            menuItem[i].MenuItemName == "dashboard-ppcbing" ||
                            menuItem[i].MenuItemName == "dashboard-myconnections") &&
                        menuItem[i].Visible == true
                    ) {
                        isCreateDashboard = true;
                    }
                }
                if (!isSocialAutomation) {
                    if (
                        menuItem[i].MenuItemName.split("-")[0] == "socialautomation" &&
                        menuItem[i].Visible == true
                    ) {
                        isSocialAutomation = true;
                    }
                }
                if (!isGMBManagement) {
                    if (
                        menuItem[i].MenuItemName.split("-")[0] == "gmbmanagement" &&
                        menuItem[i].Visible == true
                    ) {
                        isGMBManagement = true;
                    }
                }
                if (!isSignUp) {
                    if (
                        menuItem[i].MenuItemName.split("-")[0] == "signup" &&
                        menuItem[i].Visible == true &&
                        !token[uid].isReseller &&
                        token[uid].isActiveFreeTrial
                    ) {
                        isSignUp = true;
                    }
                }
                if (!isSignUpConsumer){
                    if (
                        menuItem[i].MenuItemName == "consumer-signup" &&
                        menuItem[i].Visible == true &&
                        isConsumerPlan &&
                        token[uid].isActiveFreeTrial
                    ) {
                        isSignUpConsumer = true;
                    }
                }
                if(!isEcommerce){
                    if (
                        menuItem[i].MenuItemName.split("-")[0] == "ecommerce" &&
                        menuItem[i].Visible == true &&
                        isConsumerPlan &&
                        process.env.VUE_APP_RUNNING_ON_DEV == "true"
                    ) {
                        isEcommerce = true;
                    }
                }
            }
            for (var j = 0; menuItem.length > j; j++) {
                if(isDashboard){
                    if (menuItem[j].MenuItemName == "dashboard-consumerdashboard" && menuItem[j].Visible == true && isConsumerPlan) {
                        obj[0].url= "/consumer/dashboard/uid/" + uid + "/" + vendastaAccountId;
                    }
                }
                if (isLeads) {
                    // if (
                    //     menuItem[j].MenuItemName == "leads-searchleads" &&
                    //     menuItem[j].Visible == true
                    // ) {
                    //     obj[1].submenu.push({
                    //         url: "/leads/search-lead/uid/" + uid,
                    //         name: "Search Leads",
                    //         slug: "search-lead",
                    //         i18n: "Leads"
                    //     });
                    // }
                    if (
                        menuItem[j].MenuItemName == "leads-formcaptures" &&
                        menuItem[j].Visible == true
                    ) {
                        obj[1].submenu.push({
                            url: "/leads/form-captures/uid/" + uid + "/" + vendastaAccountId,
                            name: "Form Captures",
                            slug: "form-captures",
                            i18n: "Leads"
                        });
                    }
                    if (
                        menuItem[j].MenuItemName == "leads-emailcampaigns" &&
                        menuItem[j].Visible == true
                    ) {
                        obj[1].submenu.push({
                            url: "/leads/email-campaigns/uid/" + uid + "/" + vendastaAccountId,
                            name: "Email Campaigns",
                            slug: "email-campaigns",
                            i18n: "Leads"
                        });
                    }
                    if (
                        menuItem[j].MenuItemName == "leads-reporting" &&
                        menuItem[j].Visible == true
                    ) {
                        obj[1].submenu.push({
                            url: "/leads/reporting/uid/" + uid + "/" + vendastaAccountId,
                            name: "Reporting",
                            slug: "reporting",
                            i18n: "Leads"
                        });
                    }
                    // Consumer
                    // if (
                    //     menuItem[j].MenuItemName == "leads-consumersearchleads" &&
                    //     menuItem[j].Visible == false
                    // ) {
                    //     obj[1].submenu.push({
                    //         url: "/consumer/lead/search-leads/uid/" + uid,
                    //         name: "Search Leads",
                    //         slug: "consumer-search-lead",
                    //         i18n: "Leads"
                    //     });
                    // }
                    if(menuItem[j].MenuItemName == "leads-searchleads" || menuItem[j].MenuItemName == "leads-consumersearchleads"){
                        if(menuItem[j].MenuItemName == "leads-searchleads" && menuItem[j].Visible == true && !isConsumerPlan){
                            obj[1].submenu.push({
                                url: "/leads/search-lead/uid/" + uid + "/" + vendastaAccountId,
                                name: "Search Leads",
                                slug: "search-lead",
                                i18n: "Leads"
                            });
                        }else if(menuItem[j].MenuItemName == "leads-consumersearchleads" && menuItem[j].Visible == true && isConsumerPlan){
                            obj[1].submenu.push({
                                url: "/consumer/lead/search-leads/uid/" + uid + "/" + vendastaAccountId,
                                name: "Search Leads",
                                slug: "consumer-search-lead",
                                i18n: "Leads"
                            });
                        }
                    }
                }
                if (isEcommerce) {
                    if (
                        menuItem[j].MenuItemName == "ecommerce-dashboard" &&
                        menuItem[j].Visible == true
                    ) {
                        obj[2].submenu.push({
                            url: "/ecommerce/dashboard/uid/" + uid + "/" + vendastaAccountId,
                            name: "Dashboard",
                            slug: "dashboard",
                            i18n: "dashboard"
                        });
                    }
                    if (
                        menuItem[j].MenuItemName == "ecommerce-searchcarts" &&
                        menuItem[j].Visible == true
                    ) {
                        obj[2].submenu.push({
                            url: "/ecommerce/search-carts/uid/" + uid + "/" + vendastaAccountId,
                            name: "Search Carts",
                            slug: "searchcarts",
                            i18n: "ecommerce"
                        });
                    }
                }
                if (isFindAnyone) {
                    if (
                        menuItem[j].MenuItemName == "findanyone-searchcontacts" &&
                        menuItem[j].Visible == true
                    ) {
                        obj[3].submenu.push({
                            url: "/find-anyone/search-contacts/uid/" + uid + "/" + vendastaAccountId,
                            name: "Search Contacts",
                            slug: "search-contacts",
                            i18n: "dashboard"
                        });
                    }
                    if (
                        menuItem[j].MenuItemName == "findanyone-savedcontacts" &&
                        menuItem[j].Visible == true
                    ) {
                        obj[3].submenu.push({
                            url: "/find-anyone/Save-contacts/uid/" + uid + "/" + vendastaAccountId,
                            name: "Saved Contacts",
                            slug: "saved-contacts",
                            i18n: "FindAnyone"
                        });
                    }
                    if (
                        menuItem[j].MenuItemName == "findanyone-searchcompanies" &&
                        menuItem[j].Visible == true
                    ) {
                        obj[3].submenu.push({
                            url: "/find-anyone/search-companies/uid/" + uid + "/" + vendastaAccountId,
                            name: "Search Companies",
                            slug: "search-companies",
                            i18n: "FindAnyone"
                        });
                    }
                    if (
                      menuItem[j].MenuItemName == "findanyone-dataenrichment" && menuItem[j].Visible == true )
                    {
                      obj[3].submenu.push({
                        url: "/find-anyone/data-enrichment/uid/" + uid + "/" + vendastaAccountId,
                        name: "Data Enrichment",
                        slug: "data-enrichment",
                        i18n: "FindAnyone",
                      });
                    }
                }
                if(isIntent){
                    if (
                        menuItem[j].MenuItemName == "intent-dashboard" &&
                        menuItem[j].Visible == true
                    ) {
                        obj[4].submenu.push({
                            url: "/intent/dashboard/uid/" + uid + "/" + vendastaAccountId,
                            name: "Dashboard",
                            slug: "dashboard",
                            i18n: "Dashboard"
                        });
                    }
                    if (
                        menuItem[j].MenuItemName == "intent-leads" &&
                        menuItem[j].Visible == true
                    ) {
                        obj[4].submenu.push({
                            url: "/intent/leads/uid/" + uid + "/" + vendastaAccountId,
                            name: "Leads",
                            slug: "intent-leads",
                            i18n: "Leads"
                        });
                    }
                    if (
                        menuItem[j].MenuItemName == "intent-campaigns" &&
                        menuItem[j].Visible == true
                    ) {
                        obj[4].submenu.push({
                            url: "/intent/campaigns/uid/" + uid + "/" + vendastaAccountId,
                            name: "Campaigns",
                            slug: "intent-campaigns",
                            i18n: "Campaigns"
                        });
                    }
                    if (
                        menuItem[j].MenuItemName == "intent-competitors" &&
                        menuItem[j].Visible == true
                    ) {
                        obj[4].submenu.push({
                            url: "/intent/competitors/uid/" + uid + "/" + vendastaAccountId,
                            name: "Competitors",
                            slug: "intent-competitors",
                            i18n: "Competitors"
                        });
                    }
                    if (
                        menuItem[j].MenuItemName == "intent-mycustomers" &&
                        menuItem[j].Visible == true
                    ) {
                        obj[4].submenu.push({
                            url: "/intent/mycustomerprofile/uid/" + uid + "/" + vendastaAccountId,
                            name: "My Customer Profile",
                            slug: "intent-mycustomers",
                            i18n: "Customers"
                        });
                    }
                    if (
                        menuItem[j].MenuItemName == "intent-prebuiltcampaigns" &&
                        menuItem[j].Visible == true
                    ) {
                        obj[4].submenu.push({
                            url: "/intent/prebuiltcampaigns/uid/" + uid + "/" + vendastaAccountId,
                            name: "Prebuilt Campaign",
                            slug: "intent-prebuiltcampaigns",
                            i18n: "Prebuilt"
                        });
                    }
                     if (
                        menuItem[j].MenuItemName == "intent-keywordsummary" &&
                        menuItem[j].Visible == true
                    ) {
                        obj[4].submenu.push({
                            url: "/intent/keywordsummary/uid/" + uid + "/" + vendastaAccountId,
                            name: "Keyword Summary",
                            slug: "intent-keywordsummary",
                            i18n: "Keyword"
                        });
                    }
                }
                if (isEmailMarketing) {
                    if (
                        menuItem[j].MenuItemName == "emailmarketing-dashboard" &&
                        menuItem[j].Visible == true
                    ) {
                        obj[5].submenu.push({
                            url: "/email-marketing/dashboard/uid/" + uid + "/" + vendastaAccountId,
                            name: "Dashboard",
                            slug: "email-marketing-dashboard",
                            i18n: "Marketing"
                        });
                    }
                    if (
                        menuItem[j].MenuItemName == "emailmarketing-contacts" &&
                        menuItem[j].Visible == true
                    ) {
                        obj[5].submenu.push({
                            url: "/email-marketing/contacts/uid/" + uid + "/" + vendastaAccountId,
                            name: "Contacts",
                            slug: "email-marketing-contacts",
                            i18n: "Marketing"
                        });
                    }
                    if (
                        menuItem[j].MenuItemName == "emailmarketing-campaigns" &&
                        menuItem[j].Visible == true
                    ) {
                        obj[5].submenu.push({
                            url: "/email-marketing/campaigns/uid/" + uid + "/" + vendastaAccountId,
                            name: "Campaigns",
                            slug: "email-marketing-campaigns",
                            i18n: "Marketing"
                        });
                    }
                    if (
                        menuItem[j].MenuItemName == "emailmarketing-settings" &&
                        menuItem[j].Visible == true
                    ) {
                        obj[5].submenu.push({
                            url: "/email-marketing/settings/uid/" + uid + "/" + vendastaAccountId,
                            name: "Settings",
                            slug: "email-marketing-settings",
                            i18n: "Marketing"
                        });
                    }
                }
                if (isCallTracking) {
                    if (
                        menuItem[j].MenuItemName == "calltracking-gettingstarted" &&
                        menuItem[j].Visible == true
                    ) {
                        obj[6].submenu.push({
                            url: "/call-tracking/getting-started/uid/" + uid + "/" + vendastaAccountId,
                            name: "Getting Started",
                            slug: "getting-started",
                            i18n: "CallTracking"
                        });
                    }
                    if (
                        menuItem[j].MenuItemName == "calltracking-calldashboard" &&
                        menuItem[j].Visible == true
                    ) {
                        obj[6].submenu.push({
                            url: "/call-tracking/call-dashboard/uid/" + uid + "/" + vendastaAccountId,
                            name: "Call Dashboard",
                            slug: "call-dashboard",
                            i18n: "CallTracking"
                        });
                    }
                    if (
                        menuItem[j].MenuItemName == "calltracking-textmessageinbox" &&
                        menuItem[j].Visible == true
                    ) {
                        obj[6].submenu.push({
                            url: "/call-tracking/text-msg-inbox/uid/" + uid + "/" + vendastaAccountId,
                            name: "Text Msg Inbox",
                            slug: "text-msg-inbox",
                            i18n: "CallTracking"
                        });
                    }
                    if (
                        menuItem[j].MenuItemName == "calltracking-numbers" &&
                        menuItem[j].Visible == true
                    ) {
                        obj[6].submenu.push({
                            url: "/call-tracking/numbers/uid/" + uid + "/" + vendastaAccountId,
                            name: "Numbers",
                            slug: "numbers",
                            i18n: "CallTracking"
                        });
                    }
                    if (
                        menuItem[j].MenuItemName == "calltracking-Integration" &&
                        menuItem[j].Visible == true
                    ) {
                        obj[6].submenu.push({
                            url: "/call-tracking/integration/uid/" + uid + "/" + vendastaAccountId,
                            name: "Integration",
                            slug: "integration",
                            i18n: "CallTracking"
                        });
                    }
                    if (
                        menuItem[j].MenuItemName == "calltracking-reports" &&
                        menuItem[j].Visible == true
                    ) {
                        obj[6].submenu.push({
                            url: "/call-tracking/reports/uid/" + uid + "/" + vendastaAccountId,
                            name: "Reports",
                            slug: "reports",
                            i18n: "CallTracking"
                        });
                    }
                }
                if (isRreviews) {
                    if (
                        menuItem[j].MenuItemName == "reviews-gettingstarted" &&
                        menuItem[j].Visible == true
                    ) {
                        obj[7].submenu.push({
                            url: "/reputation-management/getting-started/uid/" + uid + "/" + vendastaAccountId,
                            name: "Getting Started",
                            slug: "getting-started",
                            i18n: "ReputationManagement"
                        });
                    }
                    if (
                        menuItem[j].MenuItemName == "reviews-reviewdashboard" &&
                        menuItem[j].Visible == true
                    ) {
                        obj[7].submenu.push({
                            url: "/reputation-management/review-dashboard/uid/" + uid + "/" + vendastaAccountId,
                            name: "Review Dashboard",
                            slug: "review-dashboard",
                            i18n: "ReputationManagement"
                        });
                    }
                    if (
                        menuItem[j].MenuItemName == "reviews-reviewdetails" &&
                        menuItem[j].Visible == true
                    ) {
                        obj[7].submenu.push({
                            url: "/reputation-management/review-details/uid/" + uid + "/" + vendastaAccountId,
                            name: "Review Details",
                            slug: "review-details",
                            i18n: "ReputationManagement"
                        });
                    }
                    if (
                        menuItem[j].MenuItemName == "reviews-gatherreviews" &&
                        menuItem[j].Visible == true
                    ) {
                        obj[7].submenu.push({
                            url: "/reputation-management/gather-reviews/uid/" + uid + "/" + vendastaAccountId,
                            name: "Gather Reviews",
                            slug: "gather-reviews",
                            i18n: "ReputationManagement"
                        });
                    }
                    if (
                        menuItem[j].MenuItemName == "reviews-landingpages" &&
                        menuItem[j].Visible == true
                    ) {
                        obj[7].submenu.push({
                            url: "/reputation-management/landing-pages/uid/" + uid + "/" + vendastaAccountId,
                            name: "Landing Pages",
                            slug: "landing-pages",
                            i18n: "ReputationManagement"
                        });
                    }
                    if (
                        menuItem[j].MenuItemName == "reviews-widgets" &&
                        menuItem[j].Visible == true
                    ) {
                        obj[7].submenu.push({
                            url: "/reputation-management/widgets/uid/" + uid + "/" + vendastaAccountId,
                            name: "Widgets",
                            slug: "widgets",
                            i18n: "ReputationManagement"
                        });
                    }
                }
                if (isClientEmail) {
                    if (
                        menuItem[j].MenuItemName == "clientemail-gettingstarted" &&
                        menuItem[j].Visible == true
                    ) {
                        obj[8].submenu.push({
                            url: "/client-email/getting-started/uid/" + uid + "/" + vendastaAccountId,
                            name: "Getting Started",
                            slug: "getting-started",
                            i18n: "Client Email"
                        });
                    }
                    if (
                        menuItem[j].MenuItemName == "clientemail-emaildashboard" &&
                        menuItem[j].Visible == true
                    ) {
                        obj[8].submenu.push({
                            url: "/client-email/email-dashboard/uid/" + uid + "/" + vendastaAccountId,
                            name: "Email Dashboard",
                            slug: "email-dashboard",
                            i18n: "Client Email"
                        });
                    }
                    if (
                        menuItem[j].MenuItemName == "clientemail-activity" &&
                        menuItem[j].Visible == true
                    ) {
                        obj[8].submenu.push({
                            url: "/client-email/activity/uid/" + uid + "/" + vendastaAccountId,
                            name: "Activity",
                            slug: "activity",
                            i18n: "Client Email"
                        });
                    }
                    if (
                        menuItem[j].MenuItemName == "clientemail-templates" &&
                        menuItem[j].Visible == true
                    ) {
                        obj[8].submenu.push({
                            url: "/client-email/templates/uid/" + uid + "/" + vendastaAccountId,
                            name: "Templates",
                            slug: "templates",
                            i18n: "Client Email"
                        });
                    }
                }
                if (isAnalytics) {
                    if (
                        menuItem[j].MenuItemName == "analytics-overview" &&
                        menuItem[j].Visible == true
                    ) {
                        obj[9].submenu.push({
                            url: "/analytics/overview/uid/" + uid + "/" + vendastaAccountId,
                            name: "Overview",
                            slug: "overview",
                            i18n: "Analytics"
                        });
                    }
                    if (
                        menuItem[j].MenuItemName == "analytics-location" &&
                        menuItem[j].Visible == true
                    ) {
                        obj[9].submenu.push({
                            url: "/analytics/location/uid/" + uid + "/" + vendastaAccountId,
                            name: "Location",
                            slug: "location",
                            i18n: "Analytics"
                        });
                    }
                    if (
                        menuItem[j].MenuItemName == "analytics-device" &&
                        menuItem[j].Visible == true
                    ) {
                        obj[9].submenu.push({
                            url: "/analytics/device/uid/" + uid + "/" + vendastaAccountId,
                            name: "Device",
                            slug: "device",
                            i18n: "Analytics"
                        });
                    }
                    if (
                        menuItem[j].MenuItemName == "analytics-engagement" &&
                        menuItem[j].Visible == true
                    ) {
                        obj[9].submenu.push({
                            url: "/analytics/engagement/uid/" + uid + "/" + vendastaAccountId,
                            name: "Engagement",
                            slug: "engagement",
                            i18n: "Analytics"
                        });
                    }
                    if (
                        menuItem[j].MenuItemName == "analytics-referrer" &&
                        menuItem[j].Visible == true
                    ) {
                        obj[9].submenu.push({
                            url: "/analytics/referrer/uid/" + uid + "/" + vendastaAccountId,
                            name: "Referrer",
                            slug: "referrer",
                            i18n: "Analytics"
                        });
                    }
                    if (
                        menuItem[j].MenuItemName == "analytics-entrypages" &&
                        menuItem[j].Visible == true
                    ) {
                        obj[9].submenu.push({
                            url: "/analytics/entry/uid/" + uid + "/" + vendastaAccountId,
                            name: "Entry Page",
                            slug: "entry",
                            i18n: "Analytics"
                        });
                    }
                    if (
                        menuItem[j].MenuItemName == "analytics-exitpages" &&
                        menuItem[j].Visible == true
                    ) {
                        obj[9].submenu.push({
                            url: "/analytics/exit/uid/" + uid + "/" + vendastaAccountId,
                            name: "Exit Page",
                            slug: "exit",
                            i18n: "Analytics"
                        });
                    }
                }
                if (isLeadHandling) {
                    if (
                        menuItem[j].MenuItemName == "leadhandling-notificationrules" &&
                        menuItem[j].Visible == true
                    ) {
                        obj[10].submenu.push({
                            url: "/lead-handling/notification-rules/uid/" + uid + "/" + vendastaAccountId,
                            name: "Notification Rules",
                            slug: "notification-rules",
                            i18n: "Lead handling"
                        });
                    }
                    if (
                        menuItem[j].MenuItemName == "leadhandling-territoryrules" &&
                        menuItem[j].Visible == true
                    ) {
                        obj[10].submenu.push({
                            url: "/lead-handling/territory-rules/uid/" + uid + "/" + vendastaAccountId,
                            name: "Territory Rules",
                            slug: "territory-rules",
                            i18n: "Lead handling"
                        });
                    }
                    if (
                        menuItem[j].MenuItemName == "leadhandling-watchexcludelist" &&
                        menuItem[j].Visible == true
                    ) {
                        obj[10].submenu.push({
                            url: "/lead-handling/watch-exclude-rules/uid/" + uid + "/" + vendastaAccountId,
                            name: "Watch / Exclude Rules",
                            slug: "watch-exclude-rules",
                            i18n: "Lead handling"
                        });
                    }
                }
                if (isMyAccount) {
                    if (
                        menuItem[j].MenuItemName == "myaccount-myinformation" &&
                        menuItem[j].Visible == true
                    ) {
                        obj[11].submenu.push({
                            url: "/my-account/my-information/uid/" + uid + "/" + vendastaAccountId,
                            name: "My Information",
                            slug: "my-information",
                            i18n: "My Account"
                        });
                    }
                    if (
                        menuItem[j].MenuItemName == "myaccount-changepassword" &&
                        menuItem[j].Visible == true
                    ) {
                        obj[11].submenu.push({
                            url: "/my-account/change-password/uid/" + uid + "/" + vendastaAccountId,
                            name: "Change Password",
                            slug: "change-password",
                            i18n: "My Account"
                        });
                    }
                    if (
                        menuItem[j].MenuItemName == "myaccount-crmintegration" &&
                        menuItem[j].Visible == true
                    ) {
                        obj[11].submenu.push({
                            url: "/my-account/crm-integration/uid/" + uid + "/" + vendastaAccountId,
                            name: "CRM Integration",
                            slug: "crm-integration",
                            i18n: "My Account"
                        });
                    }
                }
                if (isIntegrationStatus) {
                    if (
                        menuItem[j].MenuItemName == "integrationstatus-targetcompanies" &&
                        menuItem[j].Visible == true
                    ) {
                        obj[12].submenu.push({
                            url: "/integration-status/targetcompanylist/uid/" + uid + "/" + vendastaAccountId,
                            name: "Target Companies",
                            slug: "target-companies",
                            i18n: "Integration Status"
                        });
                    }
                    if (
                        menuItem[j].MenuItemName == "integrationstatus-targetcontacts" &&
                        menuItem[j].Visible == true
                    ) {
                        obj[12].submenu.push({
                            url: "/integration-status/targetcontactlist/uid/" + uid + "/" + vendastaAccountId,
                            name: "Target Contacts",
                            slug: "target-contacts",
                            i18n: "Integration Status"
                        });
                    }
                    if (
                        menuItem[j].MenuItemName == "integrationstatus-jobactivities" &&
                        menuItem[j].Visible == true
                    ) {
                        obj[12].submenu.push({
                            url: "/integration-status/jobactivity/uid/" + uid + "/" + vendastaAccountId,
                            name: "Job Activity",
                            slug: "job-activity",
                            i18n: "Integration Status"
                        });
                    }
                }
                if (isBilling) {
                    // if (
                    //     menuItem[j].MenuItemName == "billing-billinginfo" &&
                    //     menuItem[j].Visible == true
                    // ) {
                    //     obj[13].submenu.push({
                    //         url: "/billing/billing-info/uid/" + uid,
                    //         name: "Billing Info",
                    //         slug: "billing-info",
                    //         i18n: "Billing"
                    //     });
                    // }
                    if (menuItem[j].MenuItemName == "billing-billinginfo" && menuItem[j].Visible == true) {
                        if(isConsumerPlan){
                            obj[13].submenu.push({
                                url: "/consumer/billing/billing-info/uid/" + uid + "/" + vendastaAccountId,
                                name: "Billing Info",
                                slug: "billing-info",
                                i18n: "Billing"
                            });
                        }else{
                            obj[13].submenu.push({
                                url: "/billing/billing-info/uid/" + uid + "/" + vendastaAccountId,
                                name: "Billing Info",
                                slug: "billing-info",
                                i18n: "Billing"
                            });
                        }
                    }
                    if (
                        menuItem[j].MenuItemName == "billing-invoices" &&
                        menuItem[j].Visible == true
                    ) {
                        obj[13].submenu.push({
                            url: "/billing/invoice/uid/" + uid + "/" + vendastaAccountId,
                            name: "Invoice",
                            slug: "invoice",
                            i18n: "Billing"
                        });
                    }
                    if (
                        menuItem[j].MenuItemName == "billing-auditlog" &&
                        menuItem[j].Visible == true
                    ) {
                        obj[13].submenu.push({
                            url: "/billing/audit-log/uid/" + uid + "/" + vendastaAccountId,
                            name: "Audit Log",
                            slug: "audit-log",
                            i18n: "Billing"
                        });
                    }
                    if (
                        menuItem[j].MenuItemName == "billing-credits" &&
                        menuItem[j].Visible == true
                    ) {
                        obj[13].submenu.push({
                            url: "/billing/credit/uid/" + uid + "/" + vendastaAccountId,
                            name: "Credits",
                            slug: "credit",
                            i18n: "Billing"
                        });
                    }
                }
                if (isConfiguration) {
                    if (
                        menuItem[j].MenuItemName == "configuration-users" &&
                        menuItem[j].Visible == true
                    ) {
                        obj[14].submenu.push({
                            url: "/configuration/user/uid/" + uid + "/" + vendastaAccountId,
                            name: "Users",
                            slug: "user",
                            i18n: "configuration"
                        });
                    }
                    if (
                        menuItem[j].MenuItemName == "configuration-domains" &&
                        menuItem[j].Visible == true
                    ) {
                        obj[14].submenu.push({
                            url: "/configuration/Domains/uid/" + uid + "/" + vendastaAccountId,
                            name: "Domains",
                            slug: "domains",
                            i18n: "configuration"
                        });
                    }
                    // if (
                    //     menuItem[j].MenuItemName == "configuration-installation" &&
                    //     menuItem[j].Visible == true
                    // ) {
                    //     obj[14].submenu.push({
                    //         url: "/configuration/installation-old/uid/" + uid,
                    //         name: "InstallationOld",
                    //         slug: "installationold",
                    //         i18n: "configuration"
                    //     });
                    // }
                    if (
                        menuItem[j].MenuItemName == "configuration-installation" &&
                        menuItem[j].Visible == true
                    ) {
                        obj[14].submenu.push({
                            url: "/configuration/installation/uid/" + uid + "/" + vendastaAccountId,
                            name: "Installation",
                            slug: "installation",
                            i18n: "configuration"
                        });
                    }
                    if (
                        menuItem[j].MenuItemName == "configuration-connectors" &&
                        menuItem[j].Visible == true && isConsumerPlan
                    ) {
                        obj[14].submenu.push({
                            url: "/configuration/connectors/uid/" + uid + "/" + vendastaAccountId,
                            name: "Connectors",
                            slug: "connectors",
                            i18n: "configuration"
                        });
                    }
                    if (
                        menuItem[j].MenuItemName == "configuration-excludeip" &&
                        menuItem[j].Visible == true
                    ) {
                        obj[14].submenu.push({
                            url: "/configuration/excludedip/uid/" + uid + "/" + vendastaAccountId,
                            name: "Exclude IP",
                            slug: "excludedip",
                            i18n: "configuration"
                        });
                    }
                    if (
                        menuItem[j].MenuItemName == "configuration-emailcampaigns" &&
                        menuItem[j].Visible == true
                    ) {
                        obj[14].submenu.push({
                            url: "/configuration/emailcampaigns/uid/" + uid + "/" + vendastaAccountId,
                            name: "Email Campaigns",
                            slug: "emailcampaigns",
                            i18n: "configuration"
                        });
                    }
                    if (
                        menuItem[j].MenuItemName == "configuration-formscapture" &&
                        menuItem[j].Visible == true
                    ) {
                        obj[14].submenu.push({
                            url: "/configuration/formscapture/uid/" + uid + "/" + vendastaAccountId,
                            name: "Forms Capture",
                            slug: "formscapture",
                            i18n: "configuration"
                        });
                    }
                    if (
                        menuItem[j].MenuItemName == "configuration-filtercompanies" &&
                        menuItem[j].Visible == true
                    ) {
                        obj[14].submenu.push({
                            url: "/configuration/filtercompanies/uid/" + uid + "/" + vendastaAccountId,
                            name: "Filter Companies",
                            slug: "filtercompanies",
                            i18n: "configuration"
                        });
                    }
                    if (
                        menuItem[j].MenuItemName == "configuration-filtercountries" &&
                        menuItem[j].Visible == true
                    ) {
                        obj[14].submenu.push({
                            url: "/configuration/filtercountries/uid/" + uid + "/" + vendastaAccountId,
                            name: "Filter Countries",
                            slug: "filtercountries",
                            i18n: "configuration"
                        });
                    }
                    if (
                        menuItem[j].MenuItemName == "configuration-filterstates" &&
                        menuItem[j].Visible == true
                    ) {
                        obj[14].submenu.push({
                            url: "/configuration/filterstates/uid/" + uid + "/" + vendastaAccountId,
                            name: "Filter States",
                            slug: "filterstates",
                            i18n: "configuration"
                        });
                    }
                    if (
                        menuItem[j].MenuItemName == "configuration-filterzipcodes" &&
                        menuItem[j].Visible == true && isConsumerPlan
                    ) {
                        obj[14].submenu.push({
                            url: "/consumer/configuration/filter-zipcodes/uid/" + uid + "/" + vendastaAccountId,
                            name: "Filter Zip codes",
                            slug: "filterzipcodes",
                            i18n: "configuration"
                        });
                    }
                    if (
                        menuItem[j].MenuItemName == "configuration-filtercontacts" &&
                        menuItem[j].Visible == true
                    ) {
                        obj[14].submenu.push({
                            url: "/configuration/filterjobtitle/uid/" + uid + "/" + vendastaAccountId,
                            name: "Filter Contacts",
                            slug: "filterjobtitle",
                            i18n: "configuration"
                        });
                    }
                    if (
                        menuItem[j].MenuItemName == "configuration-mycustomers" &&
                        menuItem[j].Visible == true
                    ) {
                        obj[14].submenu.push({
                            url: "/configuration/mycustomers/uid/" + uid + "/" + vendastaAccountId,
                            name: "My Customers",
                            slug: "MyCustomers",
                            i18n: "configuration"
                        });
                    }
                    if (
                        menuItem[j].MenuItemName == "configuration-mycompetitors" &&
                        menuItem[j].Visible == true
                    ) {
                        obj[14].submenu.push({
                            url: "/configuration/mycompetitors/uid/" + uid + "/" + vendastaAccountId,
                            name: "My Competitors",
                            slug: "MyCompetitors",
                            i18n: "configuration"
                        });
                    }
                    if (
                        menuItem[j].MenuItemName == "configuration-integrationpartners" &&
                        menuItem[j].Visible == true
                    ) {
                        obj[14].submenu.push({
                            url: "/configuration/integrationpartners/uid/" + uid + "/" + vendastaAccountId,
                            name: "Integration Partner",
                            slug: "IntegrationPartner",
                            i18n: "configuration"
                        });
                    }
                    if (
                        menuItem[j].MenuItemName == "configuration-suppressionlist" &&
                        menuItem[j].Visible == true && isConsumerPlan
                    ) {
                        obj[14].submenu.push({
                            url: "/consumer/configuration/suppression-list/uid/" + uid + "/" + vendastaAccountId,
                            name: "Suppression List",
                            slug: "SuppressionList",
                            i18n: "configuration"
                        });
                    }
                    if (
                        menuItem[j].MenuItemName == "configuration-knowncustomers" &&
                        menuItem[j].Visible == true && isConsumerPlan
                    ) {
                        obj[14].submenu.push({
                            url: "/consumer/configuration/known-customers/uid/" + uid + "/" + vendastaAccountId,
                            name: "Known Customers",
                            slug: "KnownCustomers",
                            i18n: "configuration"
                        });
                    }
                    // Consumer Configuration
                    // if ( menuItem[j].MenuItemName == "configuration-budget" && menuItem[j].Visible == true && isConsumerPlan) {
                    //     obj[14].submenu.push({
                    //         url: "/consumer/configuration/budget/uid/" + uid,
                    //         name: "Budget",
                    //         slug: "budget",
                    //         i18n: "configuration"
                    //     });
                    // }
                }
                if (isReseller) {
                    if (
                        menuItem[j].MenuItemName == "reseller-customers" &&
                        menuItem[j].Visible == true
                    ) {
                        obj[15].submenu.push({
                            url: "/reseller/customers/uid/" + uid + "/" + vendastaAccountId,
                            name: "Customers",
                            slug: "customers",
                            i18n: "reseller"
                        });
                    }
                    if (
                        menuItem[j].MenuItemName == "reseller-resellersettings" &&
                        menuItem[j].Visible == true
                    ) {
                        obj[15].submenu.push({
                            url: "/reseller/reseller-setting/uid/" + uid + "/" + vendastaAccountId,
                            name: "Reseller Settings",
                            slug: "reseller-setting",
                            i18n: "reseller"
                        });
                    }
                    if (
                        menuItem[j].MenuItemName == "reseller-manageresellers" &&
                        menuItem[j].Visible == true
                    ) {
                        obj[15].submenu.push({
                            url: "/reseller/reseller-manage/uid/" + uid + "/" + vendastaAccountId,
                            name: "Manage Resellers",
                            slug: "reseller-manage",
                            i18n: "reseller"
                        });
                    }
                    if (
                        menuItem[j].MenuItemName == "reseller-resellerreports" &&
                        menuItem[j].Visible == true
                    ) {
                        obj[15].submenu.push({
                            url: "/reseller/reseller-report/uid/" + uid + "/" + vendastaAccountId,
                            name: "Reseller Reports",
                            slug: "reseller-report",
                            i18n: "reseller"
                        });
                    }
                    if (
                        menuItem[j].MenuItemName == "reseller-billingmap" &&
                        menuItem[j].Visible == true
                    ) {
                        obj[15].submenu.push({
                            url: "/reseller/reseller-billing-map/uid/" + uid + "/" + vendastaAccountId,
                            name: "Billing Map",
                            slug: "reseller-billing-map",
                            i18n: "reseller"
                        });
                    }
                }
                if (isCreateDashboard) {
                    if (
                        menuItem[j].MenuItemName == "dashboard-gmbinsights" &&
                        menuItem[j].Visible == true
                    ) {
                        obj[16].submenu.push({
                            url: "/create-dashboard/gmb-insight/uid/" + uid + "/" + vendastaAccountId,
                            name: "GMB Insight",
                            slug: "gmb-insight",
                            i18n: "dashboard"
                        });
                    }
                    if (
                        menuItem[j].MenuItemName == "dashboard-ppcadwords" &&
                        menuItem[j].Visible == true
                    ) {
                        obj[16].submenu.push({
                            url: "/create-dashboard/ppc-adwords/uid/" + uid + "/" + vendastaAccountId,
                            name: "PPC Adwords",
                            slug: "ppc-adwords",
                            i18n: "dashboard"
                        });
                    }
                    if (
                        menuItem[j].MenuItemName == "dashboard-facebookorganic" &&
                        menuItem[j].Visible == true
                    ) {
                        obj[16].submenu.push({
                            url: "/create-dashboard/facebook-organic/uid/" + uid + "/" + vendastaAccountId,
                            name: "Facebook Organic",
                            slug: "facebook-organic",
                            i18n: "dashboard"
                        });
                    }
                    if (
                        menuItem[j].MenuItemName == "dashboard-facebookpaid" &&
                        menuItem[j].Visible == true
                    ) {
                        obj[16].submenu.push({
                            url: "/create-dashboard/facebook-paid/uid/" + uid + "/" + vendastaAccountId,
                            name: "Facebook Paid",
                            slug: "facebook-paid",
                            i18n: "dashboard"
                        });
                    }
                    if (
                        menuItem[j].MenuItemName == "dashboard-seo" &&
                        menuItem[j].Visible == true
                    ) {
                        obj[16].submenu.push({
                            url: "/create-dashboard/seo/uid/" + uid + "/" + vendastaAccountId,
                            name: "Seo",
                            slug: "seo",
                            i18n: "dashboard"
                        });
                    }
                    if (
                        menuItem[j].MenuItemName == "dashboard-programmatic" &&
                        menuItem[j].Visible == true
                    ) {
                        obj[16].submenu.push({
                            url: "/create-dashboard/programmatic/uid/" + uid + "/" + vendastaAccountId,
                            name: "Programmatic",
                            slug: "programmatic",
                            i18n: "dashboard"
                        });
                    }
                    if (
                        menuItem[j].MenuItemName == "dashboard-ppcbing" &&
                        menuItem[j].Visible == true
                    ) {
                        obj[16].submenu.push({
                            url: "/create-dashboard/ppcbing/uid/" + uid + "/" + vendastaAccountId,
                            name: "PPC-Bing",
                            slug: "ppcbing",
                            i18n: "dashboard"
                        });
                    }
                    if (
                        menuItem[j].MenuItemName == "dashboard-markup" &&
                        menuItem[j].Visible == true
                    ) {
                        obj[16].submenu.push({
                            url: "/create-dashboard/markup/uid/" + uid + "/" + vendastaAccountId,
                            name: "Markup",
                            slug: "markup",
                            i18n: "dashboard"
                        });
                    }
                    if (
                        menuItem[j].MenuItemName == "dashboard-myconnections" &&
                        menuItem[j].Visible == true
                    ) {
                        obj[16].submenu.push({
                            url: "/create-dashboard/dashboard-your-connection/uid/" + uid + "/" + vendastaAccountId,
                            name: "Your Connection",
                            slug: "your-connection",
                            i18n: "dashboard"
                        });
                    }
                }
                if (isSocialAutomation) {
                    if (
                        menuItem[j].MenuItemName == "socialautomation-publishing" &&
                        menuItem[j].Visible == true
                    ) {
                        obj[17].submenu.push({
                            url: "/social-automation/publishing/uid/" + uid + "/" + vendastaAccountId,
                            name: "Publishing",
                            slug: "publishing",
                            i18n: "social"
                        });
                    }
                    if (
                        menuItem[j].MenuItemName == "socialautomation-monitoring" &&
                        menuItem[j].Visible == true
                    ) {
                        obj[17].submenu.push({
                            url: "/social-automation/monitoring/uid/" + uid + "/" + vendastaAccountId,
                            name: "Monitoring",
                            slug: "monitoring",
                            i18n: "social"
                        });
                    }
                    // if (
                    //     menuItem[j].MenuItemName == "socialautomation-reporting" &&
                    //     menuItem[j].Visible == true
                    // ) {
                    //     obj[17].submenu.push({
                    //         url: "/social-automation/reporting/uid/" + uid,
                    //         name: "Reporting",
                    //         slug: "reporting",
                    //         i18n: "social"
                    //     });
                    // }
                    if (
                        menuItem[j].MenuItemName == "socialautomation-settings" &&
                        menuItem[j].Visible == true
                    ) {
                        obj[17].submenu.push({
                            url: "/social-automation/settings/uid/" + uid + "/" + vendastaAccountId,
                            name: "Settings",
                            slug: "settings",
                            i18n: "social"
                        });
                    }
                }
                if (isGMBManagement) {
                    if (
                        menuItem[j].MenuItemName == "gmbmanagement-localranking" &&
                        menuItem[j].Visible == true
                    ) {
                        obj[18].submenu.push({
                            url: "/gmb-management/local-ranking/uid/" + uid + "/" + vendastaAccountId,
                            name: "Local Ranking",
                            slug: "local-ranking",
                            i18n: "Management"
                        });
                    }
                }
            }
            //Need-Help static menu START
            obj[19].submenu.push({
                url: "/need-help/general-use/uid/" + uid + "/"+vendastaAccountId,
                name: "General Use",
                slug: "general-use",
                i18n: "NeedHelp"
            });
            //Need-Help static menu END

            // console.log(obj)
            let obj2 = [];
            for (var k = 0; obj.length > k; k++) {
                // console.log(k,'---',obj[k])
                if (k != 0 && k != 20 && k != 21 && obj[k].submenu.length > 0) {
                    obj2.push(obj[k]);
                }
                if (k == 0 && isDashboard) {
                    obj2.push(obj[k]);
                }
                if (k == 20 && isSignUp) {
                    obj2.push(obj[k]);
                }
                if (k == 21 && isSignUpConsumer){
                    obj2.push(obj[k]);
                }
            }
            this.navMenuItems = obj2;
        },
    },
    created() {
        // const color = this.navbarColor == "#fff" && this.isThemeDark ? "#10163a" : this.navbarColor
        let uid = this.$route.params.id;
        let VVtoken = localStorage.getItem("VVtoken");
        let token = JSON.parse(VVtoken);
        const color =
            this.navbarColor == "#fff" && this.isThemeDark ?
            token[uid].themeDetail.NavbarColor :
            token[uid].themeDetail.NavbarColor;
        this.updateNavbarColor(this.RGBToHex(color));
        this.setNavMenuVisibility(this.$store.state.mainLayoutType);

        // Dynamic Watchers for tour
        // Reason: Once tour is disabled it is not required to enable it.
        // So, watcher is required for just disabling it.
        this.dynamicWatchers.windowWidth = this.$watch(
            "$store.state.windowWidth",
            val => {
                if (val < 1200) {
                    this.disableThemeTour = true;
                    this.dynamicWatchers.windowWidth();
                }
            }
        );

        this.dynamicWatchers.verticalNavMenuWidth = this.$watch(
            "$store.state.verticalNavMenuWidth",
            () => {
                this.disableThemeTour = true;
                this.dynamicWatchers.verticalNavMenuWidth();
            }
        );

        this.dynamicWatchers.rtl = this.$watch("$vs.rtl", () => {
            this.disableThemeTour = true;
            this.dynamicWatchers.rtl();
        });
        this.getSidebarItems();
        this.getLoggedInCompanyName();
    },
    beforeDestroy() {
        Object.keys(this.dynamicWatchers).map(i => {
            this.dynamicWatchers[i]();
            delete this.dynamicWatchers[i];
        });
    }
};
</script>
