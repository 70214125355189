<template>
<div class="the-navbar__user-meta flex items-center" v-if="!vendastaIsPartner">
    <div class="text-right leading-tight hidden sm:block">
        <p class="font-semibold text-primary">{{ userName }}</p>
        <p class="small-text">{{ userRole }}</p>
        <!-- <a href="#" @click="startVVGuidedTour()">
            <p class="text-primary small-text">Start Tour</p>
        </a> -->
    </div>

    <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer">
        <div class="con-img ml-3">
            <img v-if="activeUserInfo.photoURL" key="onlineImg" :src="activeUserInfo.photoURL" alt="user-img" width="40" height="40" class="rounded-full shadow-md cursor-pointer block" />
        </div>
        <vs-dropdown-menu class="vx-navbar-dropdown">
            <ul style="min-width: 12rem">
                <li class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white">
                    <router-link :to="'/my-account/my-information/uid/'+$route.params.id">
                        <feather-icon icon="UserIcon" svgClasses="w-4 h-4" />
                        <span class="ml-2">Update My Info</span>
                    </router-link>
                </li>
                <li class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white">
                    <router-link :to="'/my-account/change-password/uid/'+$route.params.id">
                        <feather-icon icon="LockIcon" svgClasses="w-4 h-4" />
                        <span class="ml-2">Change Password</span>
                    </router-link>
                </li>
                <vs-divider class="m-1" />
                <li class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white" @click="logout()">
                    <feather-icon icon="LogOutIcon" svgClasses="w-4 h-4" />
                    <span class="ml-2">Sign Out</span>
                </li>
            </ul>
        </vs-dropdown-menu>
    </vs-dropdown>
</div>
</template>

<script>
import VueJwtDecode from 'vue-jwt-decode'
import store from '../../../../store/store'
import Cookie from "js-cookie";

export default {
    components: {
        VueJwtDecode
    },
    data() {
        return {
            userName: null,
            userRole: null,
            currentRouteName: this.$router.currentRoute.name,
            vendastaIsPartner: false,
        }
    },
    watch: {
        $route() {
            this.currentRouteName = this.$router.currentRoute.name;
        },
    },
    created() {
        let uid = this.$route.params.id;
        let VVtoken = localStorage.getItem("VVtoken");
        let token = JSON.parse(VVtoken);
        this.vendastaIsPartner=token[uid].vendastaIsPartner

        if(this.vendastaIsPartner){
            let vendastaAccountId = token[0].vendastaAccountId
            let vendastaProductId = token[0].vendastaProductId
            let vendastaThirdpartyNavbarUrl = token[0].vendastaThirdpartyNavbarUrl

            document.body.classList.add("product-navigation-bar");

            var dynamicScript = document.createElement('script');
            dynamicScript.setAttribute('src','https://www.cdnstyles.com/static/product_navbar/v1/product_navbar.js');
            dynamicScript.setAttribute('data-url',vendastaThirdpartyNavbarUrl);
            dynamicScript.setAttribute('data-account-id',vendastaAccountId);
            dynamicScript.setAttribute('data-app-id',vendastaProductId);
            document.body.appendChild(dynamicScript);
        }

        this.isLoggedIn();
        //Auth token renew method called at every 1 minute.
        if (this.$store) {
            if (this.$store.state.authTokenVV) {
                let uid = this.$route.params.id
                if (uid) {
                    let VVtoken = localStorage.getItem("VVtoken");
                    let token = JSON.parse(VVtoken);
                    if (uid == 0) {
                        if (token[uid].id == 'primary') {
                            setInterval(() => this.renewAuthToken(token[uid].refreshToken, uid), 1000 * 60 * 1)
                        }
                    }
                }
            }
        }
        //Get current version API called at every 5 minutes and if version vary from the version stored in Vuex then page will be refresh.
        setInterval(() => this.checkAppVersion(), 1000 * 300 * 1)
    },
    mounted() {
        this.getInterfaceOptions()
        this.setAppVersion();
    },
    computed: {
        activeUserInfo() {
            return this.$store.state.AppActiveUser
        }
    },
    methods: {
        // logout() {
        //   // This is just for demo Purpose. If user clicks on logout -> redirect
        //   this.$router.push('/pages/login').catch(() => {})
        // },
        getInterfaceOptions() {
            let url = 'Account/GetInterfaceOptions';
            this.axios.get(url).then(response => {
                let data = response.data;
                this.userName = data.UserInformation.FirstName + ' ' + data.UserInformation.LastName
                this.userRole = data.UserInformation.Role

                let uid = this.$route.params.id;
                let VVtoken = localStorage.getItem("VVtoken");
                let token = JSON.parse(VVtoken);
                token[uid].menuItems = data.MenuItems
                //token[uid].themeDetail = data.DashboardThemeV2
                token[uid].isReseller = data.Reseller
                token[uid].isActiveFreeTrial = data.IsActiveFreeTrial
                token[uid].isExpiredFreeTrial = data.FreeTrialIsExpired
                token[uid].resellerIsLegacyOnly = data.Reseller_Is_Legacy_Only
                token[uid].showBillingV2 = data.ShowBillingV2
                token[uid].showGuidedTour = data.ShowGuidedTour
                token[uid].userDetails = data.UserInformation
                localStorage.setItem("VVtoken", JSON.stringify(token));
                store.dispatch('updateAuthToken', token)

                this.$store.commit('UPDATE_IS_RESELLER', data.Reseller)
                this.$store.commit('UPDATE_MAX_TALL_FREE_NUMBERS', data.MaxTollFreeNumbers)
                this.$store.commit('UPDATE_MAX_REVIEW_LOCATIONS', data.MaxReviewLocations)
                this.$store.commit('UPDATE_MAX_USERS', data.MaxUsers)
                this.$store.commit('UPDATE_ENABLE_ZAPIER_INTEGRATION', data.EnableZapierIntegration)
                this.$store.commit('UPDATE_LOGGED_IN_ACCOUNT_LOGO', data.Logo)
                this.$store.commit('UPDATE_USER_DETAILS', data.UserInformation)

                this.$store.commit('UPDATE_SHOW_CALL_TRACKING_BILLING_SUMMARY_FOR_RESELLERS', data.ShowCallTrackingBillingSummaryForResellers)
                this.$store.commit('UPDATE_COMPANY_ACCOUNT_ID', data.CompanyAccountId)

                var link = document.querySelector("link[rel*='icon']") || document.createElement('link');
                link.type = 'image/x-icon';
                link.rel = 'shortcut icon';
                link.href = data.Reseller || data.Is_Consumer_Plan ? '/favicon/favicon-generic.png' : '/favicon/favicon.ico';
                document.getElementsByTagName('head')[0].appendChild(link);
            }).catch(e => {
                this.showError(e);
            });
        },
        renewAuthToken(refreshToken, uid) {
            if (Cookie.get('VVtokenGeneratedAt')) {
                let fromTime = new Date()
                let toTime = new Date(new Date(Cookie.get('VVtokenGeneratedAt')))
                let differenceTravel = toTime.getTime() - fromTime.getTime()
                let timeDifferenceInSecond = Math.floor((differenceTravel) / (1000))

                if (timeDifferenceInSecond < 600) {
                    let url = '/Account/RenewToken';
                    url += "?Email=" + this.$store.state.userDetails.Email + "&RefreshToken=" + refreshToken;
                    this.axios.post(url).then(response => {
                        this.axios.defaults.headers['Content-Type'] = 'application/json'
                        //Set new token
                        let data = response.data;
                        this.axios.defaults.headers.common['Authorization'] = 'Bearer '+data.token;
                        let VVtoken = localStorage.getItem("VVtoken");
                        let token = JSON.parse(VVtoken);
                        token[uid].token = data.token
                        localStorage.setItem("VVtoken", JSON.stringify(token));
                        this.$store.dispatch('updateAuthToken', token)
                        //Set cookie
                        let tokenGeneratedAt = new Date();
                        tokenGeneratedAt.setHours(tokenGeneratedAt.getHours() + 1);
                        Cookie.set('VVtokenGeneratedAt', tokenGeneratedAt)
                        // window.location.reload();
                    }).catch(() => {
                        this.logout()
                    });
                }
            } else {
                this.logout()
            }
        },
        setAppVersion() {
            this.axios.get('/Account/GetCurrentVersion').then(response => {
                let data = response.data;
                this.$store.commit('APP_VERSION', data)
            }).catch(() => {
                this.logout()
            });
        },
        checkAppVersion() {
            this.axios.get('/Account/GetCurrentVersion').then(response => {
                // console.log('check version called after login!!!!')
                let data = response.data;
                if (data != this.$store.state.appVersion) {
                    window.location.reload();
                }
            }).catch(() => {
                // console.log('check version called after login failed!!!!')
                // this.logout()
            });
        },
        startVVGuidedTour(){
            Cookie.remove("VVGuidedTour")
            if(this.currentRouteName == 'Dashboard'){
                window.location.reload();
            }else{
                this.$router.push({
                    name: 'Dashboard',
                });
            }
        }
    }
}
</script>

<style lang="scss">
.vs-dropdown--custom {
    ul {
        min-width: 13rem !important;
    }
}
</style>
